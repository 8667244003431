.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.error_messages {
  color: $color-red;
}

select::-ms-expand {
  display: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

input[type='tel'] {
  color: $color-black;
}

input[type='radio'] ~ label,
input[type='radio'] ~ .label {
  margin-left: 1px;
}

.page-wrapper {
  .page-header {
    z-index: 999;
  }
}

#cboxLoadedContent {
  margin: 35px 0 15px 0;
  .sign-in-component {
    overflow-y: auto;
    overflow-x: hidden;
    height: 475px;
  }
}

#colorbox {
  &.email_wish_popover {
    #cboxLoadedContent {
      margin: 0;
      .wishlist_social-links {
        margin-top: 22px;
      }
    }
  }
  &.colorbox {
    &__wishlist-confirm #cboxLoadedContent {
      margin: 0;
    }
  }
}

.ui-widget-content {
  height: 12em;
  overflow-y: auto;
  width: 38em;
}

.margin_top_bottom {
  margin: 0.8em 0;
}

header {
  .page-branding {
    a.page-branding__logo {
      float: right;
    }
    .trust-mark-elc-nodeblock {
      float: left;
      .trust_mark_header {
        height: 80px;
        position: relative;
        width: 80px;
        a.trust_mark_header__link {
          background-image: url('/media/images/global/lo3.png');
          background-repeat: no-repeat;
          height: 80px;
          position: absolute;
          text-indent: -9999px;
          top: -13px;
          width: 70px;
        }
      }
    }
  }
  &.is-alternate .page-branding {
    .trust-mark-elc-nodeblock .trust_mark_header {
      a.trust_mark_header__link {
        background-image: url('/media/images/global/lo3.png');
      }
    }
  }
  &.alt-color-white {
    .header-items {
      @media #{$xlarge-up} {
        padding: 17px 0;
        min-height: 65px;
      }
    }
  }
}

.brand-renutriv {
  .page-header {
    .trust-mark-elc-nodeblock {
      .trust_mark_header {
        a.trust_mark_header__link {
          background-image: url('/media/images/global/lo3.png');
        }
      }
    }
  }
}

.account-body {
  .account-order {
    .order-status-table {
      .order-status-table__row--header {
        display: table-row;
      }
    }
  }
}

.sign-in-page {
  .sign-in-component {
    .sign-in-component__confirm--registration {
      .sign-in-component__birthday-program {
        @media #{$medium-up} {
          padding: 0 40px 0 60px;
        }
      }
    }
  }
}

.contact-us-page {
  .contact-form__section.address-form {
    .address-form__country-container {
      div.default_country {
        margin: 10px 0 0 9px;
      }
    }
  }
}

.tiny-waitlist-overlay #waitlist {
  .field input {
    width: 188px;
    padding: 0 11px;
  }
}

.mpp-compare-vba {
  display: none;
}

#beauty_feed {
  #cboxLoadedContent {
    margin: 0;
  }
}

.boutique-lip-potion-page {
  .lip-potion__carousel_overlay {
    z-index: -9999;
  }
}

.footer-forms-location-sms-email-signup-elc-nodeblock {
  .footer-signup-email {
    width: 50%;
    .email-signup-email {
      width: 49%;
      display: inline-block;
    }
    .email-signup-mobile {
      width: 49%;
      display: inline-block;
      vertical-align: top;
    }
  }
  input[type='checkbox'] ~ label {
    float: left;
    padding-top: 0;
    margin-top: 10px;
  }
}

.spp {
  .social-share-icons {
    .social-share__link {
      &.twitter,
      &.pinterest {
        display: none;
      }
    }
  }
}

.welcome15-overlay {
  &__header {
    font: italic 40px $font-bauerbodoni-roman;
    text-transform: uppercase;
  }
  .email_popover__content {
    .email_input {
      margin-bottom: 35px;
      .form-text {
        box-shadow: unset;
        height: 39px;
        width: 270px;
        margin-#{$rdirection}: 10px;
      }
      .form-submit {
        margin-bottom: 2px;
      }
    }
    .email_signup__terms {
      p {
        margin: 0;
        text-transform: initial;
      }
      .label {
        padding: 0;
        &::before {
          display: none;
        }
      }
    }
  }
  #cboxClose {
    background: url('/media/images/pro_active_popup/close.jpg') no-repeat;
  }
  #cboxLoadedContent {
    overflow: visible !important;
    margin: 0;
    .email_popover {
      height: auto !important;
      &__social_icons {
        top: auto;
      }
      &__disclaimer {
        position: relative;
        top: auto;
      }
      .email_input {
        input[type='email'] {
          &.form-text.error {
            border: 1px red solid;
          }
        }
      }
    }
  }
}
/* Power reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-multiselect[data-reactid-powerreviews$='age'] {
          &::before {
            content: 'FILTER:';
            font-size: 12px;
          }
        }
      }
    }
  }
}

// 2018 Creative refresh
.page-header {
  &#{$alt} {
    .page-utilities {
      &__cart-icon {
        background-image: url('/media/images/global/bag-blue.png');
      }
      &__search-icon {
        background-image: url('/media/images/global/search_blue.png');
      }
      &__signin-text {
        color: $color-dark-navy;
      }
    }
  }
  .page-navigation {
    margin-top: 0;
  }
  .page-utilities-loyalty-elc-nodeblock {
    float: left;
  }
}

.utility-nav__loyalty {
  .user-loyalty-state {
    .user-logged-in,
    .user-logged-out {
      display: none;
      &__link {
        margin-left: 9px;
        color: $color-dark-navy;
        font-weight: normal;
        text-decoration: none;
      }
    }
  }
}

.lp_survey_area {
  .lp_question_wrapper {
    .lp_checkbox_wrapper {
      input[type='checkbox'] ~ label {
        &::after,
        &::before {
          background: none;
          top: -1px;
          left: -14px;
          border: none;
        }
      }
    }
  }
}
