.appt-book {
  .service-select {
    .service {
      min-height: 310px;
      .service-details {
        top: 0;
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .form-text,
      .text-form-text,
      input[type='email'],
      input[type='password'],
      input[type='tel'],
      input[type='text'],
      select,
      textarea {
        color: inherit;
      }
      .confirm-container {
        .total-time {
          margin-right: 30px;
        }
      }
    }
  }
}
