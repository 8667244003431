.ie-8 {
  .wishlist-page {
    .favorite__link-delete {
      text-indent: 5px;
    }
  }
  &#shipping {
    #shipping-page-info-panel {
      input[type='checkbox'] {
        margin-top: 5px;
      }
      input[type='radio'] {
        margin-top: 5px;
      }
      #form-opt-terms {
        input[type='checkbox'] {
          width: auto;
          margin-top: 5px;
        }
      }
    }
    #form-opt-terms {
      input[type='checkbox'] {
        width: auto;
        margin-top: 5px;
      }
    }
  }
  &#shipping_billing {
    .cboxIE {
      #cboxContent {
        float: none !important;
        #cboxClose {
          height: 30px;
          width: 30px;
        }
        #colorbox-default-shipping-checkbox {
          margin-top: 5px;
        }
      }
    }
    #billing__section {
      .main-button-wrapper {
        .address-book__add-address-button {
          float: left !important;
        }
      }
    }
  }
  .registration-page {
    .registration-page__birthday {
      .sex_container {
        span {
          input[type='radio'] {
            padding-top: 5px;
          }
        }
      }
    }
    .email-promotions__container {
      input[type='checkbox'] {
        padding-top: 5px;
      }
    }
    .title_container {
      input[type='radio'] {
        width: auto !important;
      }
    }
    .sex_container {
      input[type='radio'] {
        vertical-align: top;
      }
    }
    input[type='radio'] {
      float: none;
      vertical-align: middle;
    }
  }
  .page-navigation {
    width: 100% !important;
    margin-left: 0 !important;
    &__menu-toggle {
      left: auto;
      right: 0;
      top: 75px;
    }
  }
  .page-utilities {
    &__cart-count {
      min-width: 20px;
    }
    &__account-button {
      border-left: none;
    }
    &__search-button {
      position: relative;
    }
  }
  .order_review {
    .checkout-table {
      width: 100%;
    }
    .radio_group {
      input {
        float: none;
      }
    }
  }
  #colorbox.colorbox__quickshop {
    position: absolute !important;
    height: 725px !important;
    #cboxContent {
      height: 725px !important;
      #cboxLoadedContent {
        height: 725px !important;
      }
    }
    #cboxWrapper {
      height: 725px !important;
    }
  }
}

.hero-block__boutique-link {
  #{$ie8} & {
    background-repeat: no-repeat;
    background-image: url('/media/images/global/sprites-s38a9885fce.png');
  }
}
//General IE 8 background sprite image
.order-status-table tbody .order-status-table__row {
  td.lastchild span {
    #{$ie8} & {
      display: block;
      background-repeat: no-repeat;
      background-image: url('/media/images/global/sprites-s38a9885fce.png');
      background-size: auto auto;
      background-position: 100% -4459px;
    }
  }
  &.expanded td.lastchild span {
    #{$ie8} & {
      background-size: auto auto;
      background-position: 100% -4379px;
    }
  }
}
