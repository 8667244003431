.olapic-carousel-list-container {
  .olapic-carousel-partial-container {
    background: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_DESKTOP.jpg');
  }
}

.utility-nav__cart {
  @media #{$medium-up} {
    left: 26px;
  }
  @media #{$xlarge-up} {
    left: 50px;
  }
}

.utility-nav__account {
  @media #{$medium-up} {
    margin-right: 20px;
  }
  @media #{$xlarge-up} {
    margin-right: 10px;
  }
}
